.search-box{
    width: fit-content;
    height: fit-content;
    position: relative;
  }
  .input-search {
    height: 28px;
    width: 28px;
    border-style: none;
    padding: 7px 7px 7px 30px;
    font-size: 14px;
    letter-spacing: 2px;
    outline: none;
    border-radius: 50%;
    transition: all .5s ease-in-out;
    background-color: #FFF;
    padding-right: 0;
    color: #42003F;
    position: absolute;
}
  .input-search::placeholder{
    color:rgba(255,255,255,.5);
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: 100;
  }
  .btn-search {
    width: 28px;
    height: 28px;
    border-style: none;
    font-size: 14px;
    font-weight: bold;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    color: #42003F;
    background-color: #FFF;
    pointer-events: painted;
    position: absolute;
    top: 0px;
    z-index: 4;
}
  .btn-search:focus ~ .input-search{
    width: 300px;
    border-radius: 0px;
    background-color: #FFF;
    /* border-bottom:1px solid rgba(255,255,255,.5); */
    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
  }
  .input-search:focus{
    width: 300px;
    border-radius: 10px;
    background-color: #FFF;
    /* border-bottom:1px solid rgba(255,255,255,.5); */
    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
  }
  #Search::-webkit-search-cancel-button{
    position:relative;
    right:20px;    
}